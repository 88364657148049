import React from 'react'
import Sectitle from '../SectionTitle';
import ErpServiceitem from '../ServiceItem';

const features = [{
    title: 'Backtest',
    text: 'Choose from a selection of programming tools, including block coding and Python text-based programming for free, to build complex strategies and backtest and evaluate them before going live.',
    icon: 'backtest.png',
    class: 'pr_70'
},{
    title: 'Portfolio Risk Analysis',
    text: 'Perform a comprehensive backtesting analysis of your strategy for free, evaluating its performance and risk metrics.',
    icon: 'portfolio-risk-analysis.png',
    class: "pr_70 pl_10"
},
{
    title: 'Paper',
    text: 'Paper trading takes place during open market hours, allowing you to keep track of price changes while running your trading strategy without risking any money',
    icon: 'paper.png',
    class: 'pr_70'
},
{
    title: 'Live',
    text: `When you're happy, upload your code to our cloud and start trading live! We provide integrations to several brokers, allowing you to run your algorithms
    against a variety of instruments and exchanges`,
    icon: 'live.png',
    class: 'pr_70'
},
{
    title: 'Market Place',
    text: `We provide a variety of well-known trading strategies via our marketplace. You
    may also publish and get paid for your profitable strategies`,
    icon: 'marketplace.png',
    class: "pr_70 pl_10"
},
{
    title: 'AI & ML',
    text: `Simply upload your machine learning model to our cloud, and we'll host and make
    it available to your trading environment.`,
    icon: 'ai.png',
    class: 'pr_70'
}
]

const Services =()=> {
    return(
        <section id="product" className="erp_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title erp_title text-center" Title='The AlgoTrady Universes' 
                // TitleP='The full monty burke posh excuse my French Richard cheeky bobby spiffing crikey Why gormless, pear shaped.!'
                />
                <div className="row">
                    {
                        features.map(feature => {
                            return <ErpServiceitem itemClass={feature.class} sTitle={feature.title} pText={feature.text} icon={feature.icon}/>
                        })
                    }
               </div>
            </div>
        </section>
    )
}

export default Services;