import React from 'react';

const Steps = () => {
    return (
      <section className="event_fact_area sec_pad">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2>
            <span>Everything is done to help you</span> achieve your goals..
            </h2>
          </div>
          <div className="seo_fact_info">
            <div className="seo_fact_item wow fadeIn">
              <div className="text">
                <div className="counter one"><img src={require ("../img/steps/backtest.png")} alt="coding"/></div>
                <p>Backtesting</p>
              </div>
            </div>
            <div className="seo_fact_item wow fadeIn" data-wow-delay="0.3s">
              <div className="text">
                <div className="counter two"><img src={require ("../img/steps/paper.png")} alt="coding"/></div>
                <p>Papertrading</p>
              </div>
            </div>
            <div className="seo_fact_item wow fadeIn" data-wow-delay="0.6s">
              <div className="text">
                <div className="counter three"><img src={require ("../img/steps/live.png")} alt="coding"/></div>
                <p>Livetrading</p>
              </div>
            </div>
            <div className="seo_fact_item last wow fadeIn" data-wow-delay="0.9s">
              <div className="text">
                <div className="counter four"><img src={require ("../img/steps/monitoring.png")} alt="coding"/></div>
                <p>Monitoring</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  export default Steps