import React from 'react';
import Sectitle from "../Title/Sectitle";
import ProtectionItem from "./ProtectionItem";

const Erpanalytics =()=>{
    return(
        <section className="erp_analytics_area">
            <div className="container">
                <Sectitle Title="Your algorithms are completely secure, and your strategies are," sClass="hosting_title erp_title text-center"/>
                <div className="row">
                    <ProtectionItem analyticsImage="browser.png" text="Executed in a safe and isolated environment"/>
                    <ProtectionItem analyticsImage="cloud-computing.png" text="Always accessible in our cloud and runs with no downtime"/>
                    <ProtectionItem analyticsImage="fingerprint.png" text="End-to-end encrypted, so we can't see them"/>
                </div>
            </div>
        </section>
    )
}
export default Erpanalytics;