import React from 'react';

import CustomNavbar from '../components/Navi';
import Intro from '../components/Intro';
import Services from '../components/Services';
import Steps from '../components/Steps';
import Comparison from '../components/Comparison';
import Protection from '../components/Protection';
import Brokers from '../components/Brokers';
import Team from '../components/Team';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';
import FooterData from '../components/Footer/FooterData';

const Home = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto"/>
            <Intro/> 
            <Comparison/>
            <Steps/>
            <Services/>
            <Protection/>
            <Brokers/>
            <Team />
            <Subscribe />
            <Footer fClass="h_footer_dark_two" FooterData={FooterData}/>
        </div>
    )
}
export default Home;