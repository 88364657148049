import React from 'react';

const Intro =()=>{
    return(
        <section id="intro" className="erp_banner_area_two">
            <ul className="list-unstyled cloud_animation">
                <li><img src={require ("../img/erp-home/cloud_01.png")} alt=""/></li>
                <li><img src={require ("../img/erp-home/cloud_02.png")} alt=""/></li>
                <li><img src={require ("../img/erp-home/cloud_03.png")} alt=""/></li>
                <li><img src={require ("../img/erp-home/cloud_04.png")} alt=""/></li>
                <li><img src={require ("../img/erp-home/cloud_05.png")} alt=""/></li>
                <li><img src={require ("../img/erp-home/cloud_06.png")} alt=""/></li>
            </ul>
            <div className="erp_shap"></div>
            <div className="section_intro">
                <div className="section_container">
                    <div className="intro">
                        <div className=" intro_content">
                            <h1>Simple, Fast and Effective Algorithmic Trading Platform</h1>
                            <p>We enable quickest and easiest way to take a concept to live trading, avoiding all of the hassles involved with setting up platform</p>
                            <a href="/#subscribe" className="er_btn er_btn_two">Notify Me</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="animation_img wow fadeInUp" data-wow-delay="0.3s">
                <div className="container">
                    <img src={require ("../img/terminal.png")} width="1070" alt=""/>
                </div>
            </div>
        </section>
    )
}

export default Intro;