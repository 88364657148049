import React from 'react';
import ErpFeaturesitem from './FeatureBlockItem';


const features = [{
    heading: `What's wrong with everything?`,
    paragraph: 'The abilities required to trade programmatically are not available in any concentrated pool and market fragmentation leads traders to utilise several data, execution, and risk management suppliers.',
    feature1 : 'I would need skills unrelated to trading, for setting up infrastructure.',
    feature2: `I must rewrite code every time I switch brokers.`,
    feature3: `Why should I pay for a full day's server costs if my strategy runs once or twice a day?`,
    feature4: `Can't I straightway use well-known trading strategies without coding them?`,
    feature5: "Is there a way I can monetise my successful trading strategies?",
    rowClass: "align-items-center flex-row-reverse",
    roundClass: 'red',
    image: "Bankruptcy-pana.png"
},{
    heading: `Don't worry, we provide,`,
    paragraph: 'The most significant thing about Algotrady is the comprehensive platform and comprehensive solution, which encompasses every facet of the trading cycle, enabling traders to focus on trading logic while enjoying a coherent user experience and a simplified workflow.',
    feature1 : 'A range of tools and services to make process simpler. Allowing you to move from idea to live trading quickly and easily.',
    feature2: 'A unified interface for all brokers, with tools like block coding and Python text-based programming.',
    feature3: `A pricing model based on execution count in contrast to a running server cost.`,
    feature4: ` A marketplace featuring a variety of well-known strategies and also the ability to monetise your own strategies.`,
    rowClass: "align-items-center",
    roundClass: 'green',
    image: "successful.png"
}]
const FeatureBlock =()=>{
    return(
        <section className="erp_features_area_two sec_pad">
            <div className="container">
                {features.map(feature => {
                    return <ErpFeaturesitem feature1={feature.feature1} feature2={feature.feature2} feature3={feature.feature3} feature4={feature.feature4} feature5={feature.feature5} heading={feature.heading} paragraph={feature.paragraph} rowClass={feature.rowClass} roundClass={feature.roundClass} image={feature.image}/>
                })}
{/*                 
                <ErpFeaturesitem rowClass="align-items-center" image="crm_img2.jpg" roundClass="red"/>
                <ErpFeaturesitem rowClass="align-items-center flex-row-reverse" image="crm_img3.jpg" roundClass="green"/> */}
            </div>
        </section>
    )
}
export default FeatureBlock;