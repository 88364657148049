import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
class Brokers extends Component {
    constructor(){
        super();
        this.state = {
            posts:[
                {
                    "id": 1,
                    "image": "zerodha.png",
                },
                {
                    "id": 2,
                    "image": "binance.png",
                },
                {
                    "id": 3,
                    "image": "coinbase.png",
                },
                {
                    "id": 4,
                    "image": "ftx.png",
                },
                {
                    "id": 5,
                    "image": "alpaca.png",
                },
                {
                    "id": 6,
                    "image": "kraken.png",
                },
                {
                    "id": 7,
                    "image": "aax.jpeg",
                },
                {
                    "id": 8,
                    "image": "waves-exchange.png",
                },
                {
                    "id": 9,
                    "image": "upstox.png",
                },
                {
                    "id": 10,
                    "image": "gateio.png",
                },
                {
                    "id": 11,
                    "image": "kucoin.jpeg",
                }
            ]
        };
    }
    render(){
        return(
            <section className="erp_customer_logo_area">
                <div className="container">
                    <div className="hosting_title erp_title text-center">
                        <h2>Connect with your favourite broker</h2>
                    </div>
                    <Fade bottom cascade>
                        <div style={{display:'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}} className="animation_inner">
                                {this.state.posts.map(post =>(
                                    <div key={post.id}><div className="item"><img src={require ("../img/erp-home/img/" + post.image)} alt=""/></div></div>
                                ))}
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default Brokers;