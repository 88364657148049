import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from './Teamitem';
import Reveal from 'react-reveal';
const AppTeam =()=>{
    return(
        <section className="experts_team_area sec_pad" id="team">
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title="The Experts Team" tClass="t_color3" TitleP=""/>
                <Reveal bottom cascade>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-2 col-sm-3">
                            <Teamitem teamImage="rameez.jpg" linkedInUrl="https://www.linkedin.com/in/rameez-shuhaib-505a29106/" memberN="Rameez Shuhaib" memberd="Backend Engineer"/>
                        </div>
                        </div>
                        <div className ="row justify-content-center">
                        <div className="col-lg-2 col-sm-3">
                            <Teamitem teamImage="praveen.jpg" linkedInUrl="https://www.linkedin.com/in/ptmv7/" memberN="Praveen Thomas" memberd="Backend Engineer"/>
                        </div>
                        <div className="col-lg-2 col-sm-3">
                            <Teamitem teamImage="subhin.jpg" linkedInUrl="https://www.linkedin.com/in/subhin-antony/" memberN="Subhin" memberd="Data Scientist"/>
                        </div>
                        <div className="col-lg-2 col-sm-3">
                            <Teamitem teamImage="nimesh.jpg" linkedInUrl="https://www.linkedin.com/in/nimesh-reddy-b00a29ba/" memberN="Nimesh" memberd="Backend Engineer"/>
                        </div>

                        </div>
                </Reveal>
            </div>
        </section>
        
    )
}
export default AppTeam;