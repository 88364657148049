import React, {Component} from 'react';
import ErpIconitem from './FeatureBlockItemIcon'

class FeatureBlockItem extends Component {
    render(){
        let {rowClass, roundClass, image, heading, feature1, feature2, feature3, feature4, feature5} = this.props;
        return(
            <div className={`row erp_item_features ${rowClass}`}>
                <div className="col-lg-6">
                    <div className="erp_features_img_two">
                        <div className={`img_icon ${roundClass}`}><span className="pluse_1"></span><span className="pluse_2"></span><i className="icon_lightbulb_alt"></i></div>
                        <img src={require ("../../img/erp-home/" + image)} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="erp_content_two">
                        <div className="hosting_title erp_title">
                            <h2>{heading}</h2>
                            {/* <p>{paragraph}</p> */}
                        </div>
                        <ErpIconitem eitemClass={roundClass}  iconName="icon_menu-square_alt2" erpText={feature1}/>
                        <ErpIconitem eitemClass={roundClass}  iconName="icon_menu-square_alt2"  erpText={feature2}/>
                        {feature3 &&   <ErpIconitem eitemClass={roundClass} iconName="icon_menu-square_alt2"  erpText={feature3}/>}
                        {feature4 &&   <ErpIconitem eitemClass={roundClass} iconName="icon_menu-square_alt2"  erpText={feature4}/>}
                        {feature5 &&   <ErpIconitem eitemClass={roundClass} iconName="icon_menu-square_alt2"  erpText={feature5}/>}
                    </div>
                </div>
            </div>
        )
    }
}
export default FeatureBlockItem;