import React, {useEffect} from 'react';
import {Link, NavLink,useHistory} from 'react-router-dom';
import Sticky from 'react-stickynode'; 

function CustomNavbar(props) {
    let history = useHistory();

    useEffect(()=> {
        console.log("updated")
        hashLinkScroll()
    },[history.location])


    function hashLinkScroll() {
        const { hash } = window.location;
        if (hash !== '') {
          // Push onto callback queue so it runs after the DOM is updated,
          // this is required when navigating from a different page so that
          // the element is rendered on the page before trying to getElementById.
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView();
          }, 0);
        }
      }
    
        var {mClass, nClass, cClass, slogo, hbtnClass} =props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo2.png")} alt=""/>
                            <img style={{maxHeight: 25}} src={require("../img/logo.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/#intro">Home </NavLink></li>
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/#product">Product</NavLink></li>
                                <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/#team">Team</NavLink></li>
                            </ul>
                            <a className={`btn_get btn_hover ${hbtnClass}`} href="#subscribe">Notify Me</a>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
}


export default CustomNavbar;